// **  Initial State
const initialState = {
    materialRequests: [],
    total: 0,
    createMaterialRequestStatus: null,
    createMaterialRequestError: false,
    updateMaterialRequestStatus: null,
    updateMaterialRequestError: false,
    deleteMaterialRequestStatus: false,
}

const MaterialRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_MATERIAL_PURCHASE_REQUESTS':
            return {...state, total: action.total, materialRequests: action.payload}
        case 'CREATE_MATERIAL_REQUEST_STATUS':
            return {...state, createMaterialRequestStatus: action.payload}
        case 'CREATE_MATERIAL_REQUEST_ERROR':
            return {...state, createMaterialRequestError: action.payload}
        case 'UPDATE_MATERIAL_REQUEST_STATUS':
            return {...state, updateMaterialRequestStatus: action.payload}
        case 'UPDATE_MATERIAL_REQUEST_ERROR':
            return {...state, updateMaterialRequestError: action.payload}
        case 'DELETE_MATERIAL_REQUEST_STATUS':
            return {...state, deleteMaterialRequestStatus: action.payload}
        case 'CLEAR_MATERIAL_REQUEST_STATUS':
            return {
                ...state,
                deleteMaterialRequestStatus: false,
                createMaterialRequestStatus: false,
                updateMaterialRequestStatus: false,
                createMaterialRequestError: false
            }
        default:
            return state
    }
}

export default MaterialRequestReducer
