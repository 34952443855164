// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import user from "./users";
import permission from "./permission";
import roles from "./roles";
import users from "./users";
import products from "./product";
import orders from "./orders";
import flow from "./flow";
import accounting from "./accounting";
import logs from "./log";
import notification from "./notification";
import materials from "./material";
import classes from "./classes";
import depots from "./depot";
import warehouses from "./warehouse";
import units from "./unit";
import movements from "./movement";
import stocks from "./stock";
import materialRequest from "./materialRequest";

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  user,
  roles,
  permission,
  users,
  products,
  orders,
  flow,
  accounting,
  logs,
  notification,
  materials,
  classes,
  depots,
  warehouses,
  units,
  movements,
  stocks,
  materialRequest,
});

export default rootReducer;
