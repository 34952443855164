
// **  Initial State
const initialState = {
  materialRequests: [],
  materialRequest: {},
  total: 0,
  createMaterialRequestStatus: null,
  createMaterialRequestError: false,
};

const materialRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ALL_MATERIAL_REQUESTS':
      return {...state, total: action.total, materialRequests: action.payload}
    case "CREATE_MATERIAL_REQUEST_STATUS":
      return { ...state, createMaterialRequestStatus: action.payload };
    case "CREATE_MATERIAL_REQUEST_ERROR":
      return { ...state, createMaterialRequestError: action.payload };
    case "CLEAR_MATERIAL_REQUEST_STATUS":
      return {
        ...state,
        createMaterialRequestStatus: null,
        createMaterialRequestError: false,
      };
    default:
      return state;
  }
};

export default materialRequestReducer;
